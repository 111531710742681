export const paramsConfig = {
  page: {
    state: {
      encode: (value) => {
        if (1 !== value) {
          return value;
        }
      },
      decode: (value) => {
        return parseInt(value);
      },
    },
    request: {
      encode: (value) => {
        return parseInt(value);
      },
    },
  },
  limit: {
    state: {
      encode: (value) => {
        if (24 !== value) {
          return value;
        }
      },
      decode: (value) => {
        return parseInt(value);
      },
    },
    request: {
      encode: (value) => {
        return parseInt(value);
      },
      alias: "pageSize",
    },
  },
};

import { useAsyncStrapiApi } from "~/uses/useMyFetch";
import {
  getCategoriesQuery,
  getTagsQuery,
} from "~/nuxt_modules/blog/utils/query.utils";
import { paramsConfig } from "~/nuxt_modules/blog/config/params.config";

export const initialState = () => {
  return {
    page: 1,
    limit: 24,
  };
};

export const useBlogStore = defineStore(
  "blogStore",
  () => {
    const categories = ref(null);
    const tags = ref(null);
    const params = ref(initialState());

    const fetchCategories = async () => {
      return await useAsyncStrapiApi(
        "fetchBlogCategories",
        `/blog/categories?${getCategoriesQuery()}`,
      ).then(({ data }) => {
        categories.value = data.value.data;
      });
    };

    const fetchTags = async () => {
      return await useAsyncStrapiApi(
        "fetchBlogTags",
        `/blog/tags?${getTagsQuery()}`,
      ).then(({ data }) => {
        tags.value = data.value.data;
      });
    };

    const setPage = (page) => (params.value.page = page);

    const initParams = (paramsS = {}) => {
      params.value = initialState();

      for (let paramKey in params.value) {
        const paramValue = useRoute().query[paramKey] ?? paramsS[paramKey];

        if (undefined === paramValue) {
          continue;
        }

        params.value[paramKey] =
          paramsConfig[paramKey].state.decode(paramValue);
      }
    };

    const getRequestParams = () => {
      const requestParams = {};

      for (let [paramKey, paramValue] of Object.entries(params.value)) {
        requestParams[paramsConfig[paramKey].request?.alias ?? paramKey] =
          paramsConfig[paramKey].request?.encode(paramValue) ??
          paramsConfig[paramKey].state.encode(paramValue);
      }

      return requestParams;
    };

    const getTag = (tagSlug) => {
      return tags.value?.find(({ slug }) => slug === tagSlug);
    };

    const getCategory = (categorySlug) => {
      return categories.value?.find(({ slug }) => slug === categorySlug);
    };

    return {
      categories,
      tags,
      params,
      fetchCategories,
      fetchTags,
      setPage,
      initParams,
      getRequestParams,
      getTag,
      getCategory,
    };
  },
  {
    persist: {
      debug: true,
      storage: {
        setItem(store, state) {
          const params = JSON.parse(state).params;
          const query = {};

          for (const paramKey in params) {
            const encodedParamValue = paramsConfig[paramKey].state.encode(
              params[paramKey],
            );

            if (undefined !== encodedParamValue) {
              query[paramKey] = encodedParamValue;
            }
          }

          return navigateTo({
            query,
          });
        },
        getItem(store) {},
      },
    },
  },
);
